import React, { useState, useEffect } from 'react';
import heroImage from '../resources/hero.webp';
import logoImage from '../resources/logo.svg';
import logoMobileImage from '../resources/logo_mobile.png';
import '../stylings/Hero.css'; 

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentLogo = windowWidth < 950 ? logoMobileImage : logoImage;

  return (
    <div 
      id="home" 
      className="hero-container"
      style={{
        backgroundImage: `url('${heroImage}')`
      }}
    >
      <div className="hero-content-overlay">
        <div className="hero-content-wrapper">
          <div className="hero-logo-container">
            <img 
              src={currentLogo}
              alt="Company Logo"
              className="hero-logo"
            />
          </div>
          
          <div className="hero-text-content">
            <h1 className="hero-title">
              New Orleans Tourism & Transportation Co.
            </h1>
            
            <p className="hero-description">
              Experience the best tours and reliable transportation services in the
              New Orleans area!
            </p>
            
            <button className="hero-button">
              Book Your Tour Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;