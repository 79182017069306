import React from 'react';
import { Link } from 'react-router-dom';
import armandPhoto from '../resources/armand_photo.jpg';  // Assuming this is the correct path
import '../stylings/About.css';

const About = () => {
  return (
    <div class="container" id='about'>
        <div class="about-section">
            <div class="about-image">
                <img src={armandPhoto} alt="Photo of Armand"/>
            </div>
            <div class="about-details">
              <h1 id='about'>Discover the Heart and Soul of New Orleans with Armand Bertin</h1>
              <p id='about'>Step into the vibrant world of New Orleans with Armand Bertin, your passionate and knowledgeable guide to the Big Easy. Born and raised in the city's colorful streets, Armand brings a lifetime of local expertise to every tour, offering you an authentic and unforgettable experience that goes far beyond the typical tourist trails.</p>
              <p id='about'>With over 8 years as a professional driver, Armand navigates the city's historic neighborhoods and hidden gems with ease, ensuring your comfort and safety as you explore. But what truly sets Armand apart is his 15-year culinary background. As a former insider in New Orleans' world-renowned food scene, Armand doesn't just show you the city - he invites you to taste it. From mouthwatering beignets to savory gumbo, from quaint local eateries to award-winning restaurants, Armand's tours are a feast for all your senses. Let Armand be your key to unlocking the rich tapestry of flavors, sounds, and sights that make New Orleans a truly one-of-a-kind destination.</p>
              <p id='about'>Book your tour today and let the good times roll!</p>                 
              <button class="order-button" onclick="openCheckout()">Book Your Tour Now</button>
            </div>
        </div>
    </div>
  );
};

export default About;