import React from 'react';
import car from '../resources/car.png';
import canoe from '../resources/canoe.png'
import fq from '../resources/fq.png'
import '../stylings/Transportation.css';


const Transportation = () => {
  return (
    <div className="container" id='transport'>
      <div className="transportation-details">
        <h1 id='transport'>Transportation Rates and Services</h1>
        <p id='transport'>We value transparency and honesty, and as part of that we boast a clear and transparent rate structure. This is to ensure that our drivers are paid a fair wage and that you can know exactly what you are paying for.</p>
        <ul id='transport'>
          <li id='transport'>Day Rate (trips greater than 6 hours): $250 - Hire a private driver for the day</li>
          <li id='transport'>Hourly Rate (trips between 1 and 6 hours): $50 / hr - Pay for your driver per hour. This is useful if you need to do multiple things around the city and need a driver on standby.</li>
          <li id='transport'>Per Mile: $2 per mile - Pay for what you use for small trips</li>
        </ul>
        <p id='transport'>We pick up and drop off at the airport, hotels, restaurants, and much more! Feel free to schedule with our drivers pickup times and locations. </p>
      </div>
        
      <div className="transportation-section">
          
        <div className="transport-container">
          <div className="transport-package" id="french_quarter">
            <div className="transport-image-container">
              <img src={car} alt="Car adventure" />
            </div>
            <div className="transport-info">  
              <p>Why worry about parking when you can get a driver all day? </p>
            </div>
            <h3>Day Rate: $250</h3>
          </div>
          
          <div className="transport-package">
            <div className="transport-image-container">
              <img src={canoe} alt="Swamp and Cypress Sites"/>
            </div>
            <div className="transport-info">
              <p>Going somewhere far or need someone to watch you bags? Look no further.</p>
            </div>
            <h3>Hourly Rate: $50</h3>
          </div>
          
          <div className="transport-package">
            <div className="transport-image-container">
              <img src={fq} alt="Food Tour"/>
            </div>
            <div className="transport-info">
              <p>Just need a short ride? No problem, we've got you covered.</p>
          </div>
            <h3>Per Mile: $2</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transportation;

