// Contact.jsx
import React from 'react';
import nolaMap from '../resources/nola_map.jpg';  // Make sure to move the image to your resources folder
import '../stylings/Contact.css';


const Contact = () => {
  return (
    <div className="container" id='contact'>
        <div className="contact-section">
            <div className="contact-details" id='contact'>
              <h1 id='contact'>Contact Us</h1>
              <p id='contact'>Thank you for your interest in our company! If you have any questions, we are happy to help. Below you can find a number of ways to get in touch. We promise to get back to you as soon as we can!</p>
              <ul id='contact'> 
                <li id='contact'><i className="fa fa-phone"></i>(504) 376-7730</li>
                <li id='contact'><i className="fa fa-envelope"></i>nolatours@gmail.com</li>
                <li id='contact'><i className="fa fa-facebook-square"></i>Nola Tours & Transportation</li>
                <li id='contact'><i className="fa fa-instagram"></i>@nolatoursandtransportation</li>
              </ul>
              <div className="contact-info">
                
                <p></p>
              </div>
            </div>
            <div className="contact-image">
              <img src={nolaMap} alt="contact Cover"/>
            </div>
        </div>
    </div>
  );
};

export default Contact;

