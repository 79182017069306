import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../stylings/BookingModal.css';

const BookingModal = ({ isOpen, onClose, tour }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  
  if (!isOpen || !tour) return null;

  // Generate available times for demo - in real app, this would come from API
  const getAvailableTimes = () => {
    return [
      "9:00 AM",
      "10:00 AM",
      "11:00 AM",
      "1:00 PM",
      "2:00 PM",
      "3:00 PM",
    ];
  };

  const handleDateSelect = (date) => {
    // If clicking the same date, clear the selection
    if (selectedDate && date.getTime() === selectedDate.getTime()) {
      setSelectedDate(null);
      setSelectedTime(null);
    } else {
      setSelectedDate(date);
      setSelectedTime(null);
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  // For demo - in real app, this would come from API
  const disabledDates = [
    new Date(2024, 10, 10),
    new Date(2024, 10, 12),
    new Date(2024, 10, 15),
  ];

  const isBookingComplete = selectedDate && selectedTime;

  const modalStyle = {
    backgroundImage: `url(${tour.imageUrl})`,
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" style={modalStyle} onClick={e => e.stopPropagation()}>
        <div className="booking-modal-backdrop">
          <button className="close-button" onClick={onClose}>×</button>
          
          <div className="modal-grid">
            {/* Left side - Tour details */}
            <div className="booking-details">
              <h2 className="modal-title">{tour.title}</h2>
              <div className="booking-info">
                <p className="booking-description">{tour.description}</p>
                <div className="booking-metadata">
                  <div className="tour-price">
                    <span className="booking-icon">$</span>
                    <span className="amount">{tour.price}</span>
                  </div>
                  <div className="tour-duration">
                    <span className="booking-icon">⏱</span>
                    <span>{tour.duration}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side - Booking interface */}
            <div className="booking-interface">
              <div className="calendar-container">
                <Calendar
                  value={selectedDate}
                  onChange={handleDateSelect}
                  minDate={new Date()}
                  tileDisabled={({date}) => 
                    disabledDates.some(disabledDate => 
                      disabledDate.toDateString() === date.toDateString()
                    )
                  }
                />
              </div>

              {selectedDate && (
                <div className="time-slots">
                  <h3>Available Times</h3>
                  <div className="time-slots-grid">
                    {getAvailableTimes().map((time) => (
                      <button
                        key={time}
                        className={`time-slot ${selectedTime === time ? 'selected' : ''}`}
                        onClick={() => handleTimeSelect(time)}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              <button
                className={`book-button ${isBookingComplete ? 'active' : 'inactive'}`}
                disabled={!isBookingComplete}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;