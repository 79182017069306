import React, { useState } from 'react';
import BookingModal from './BookingModal';
import '../stylings/TourCard.css';

const TourCard = ({ title, description, price, duration, rating, imageUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span 
        key={index} 
        className={`star ${index < rating ? 'filled' : ''}`}
      >
        ★
      </span>
    ));
  };

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="tour-card" onClick={handleCardClick}>
        <div className="card-image">
          <img 
            src={imageUrl} 
            alt={`${title} tour`}
          />
        </div>
        <div className="tour-content">
          <h3 className="tour-title">{title}</h3>
          <p className="tour-description">{description}</p>
          <div className="card-info">
            <div className="tour-price">
              <span className="icon">$</span>
              <span>{price}</span>
            </div>
            <div className="tour-duration">
              <span className="icon">⏱</span>
              <span>{duration}</span>
            </div>
          </div>
          <div className="tour-rating">
            {renderStars(rating)}
            <span className="rating-text">({rating}/5)</span>
          </div>
        </div>
      </div>

      <BookingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tour={{ title, description, price, duration, rating, imageUrl }}
      />
    </>
  );
};

export default TourCard;