import React, { useState } from 'react';
import '../stylings/CheckoutModal.css'; // Import the CSS file

const CheckoutModal = ({ isOpen, onClose }) => {
  const [status, setStatus] = useState({ message: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = event.target;
    const formData = {
      name: form.name.value,
      email: form.email.value,
      phone: form.phone.value,
      address: form.address.value,
      timestamp: new Date().toISOString()
    };

    try {
      // Your form submission logic here
      const scriptUrl = 'https://script.google.com/macros/s/AKfycbwwDgr4l_6Y6ZZzLhWIKFfdLzQq_laxp_KEbP4NF9C4lpTpTvsnUe_rv-0N3s1KP-4b2Q/exec';
      
      const queryString = Object.keys(formData)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
        .join('&');
      
      const fullUrl = `${scriptUrl}?${queryString}`;
      
      await new Promise((resolve, reject) => {
        const callbackName = 'callback_' + Math.random().toString(36).substr(2, 9);
        window[callbackName] = function(response) {
          delete window[callbackName];
          document.head.removeChild(script);
          resolve(response);
        };
        
        const script = document.createElement('script');
        script.src = `${fullUrl}&callback=${callbackName}`;
        script.onerror = () => {
          delete window[callbackName];
          document.head.removeChild(script);
          reject(new Error('Script loading failed'));
        };
        
        document.head.appendChild(script);
      });

      setStatus({ message: 'Order placed successfully! Thank you.', type: 'success' });
      form.reset();
      setTimeout(onClose, 3000);
    } catch (error) {
      console.error('Submission error:', error);
      setStatus({ message: 'Error submitting order. Please try again.', type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className={`modal-backdrop ${isOpen ? 'open' : ''}`}
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal-container">
        <button 
          className="close-button"
          onClick={onClose}
        >
          ×
        </button>

        <div className="modal-header">
          <h2>Reserve Your Tour</h2>
        </div>

        <form onSubmit={handleSubmit} className="checkout-form">
          <div className="form-group">
            <label>Full Name</label>
            <input
              type="text"
              name="name"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              name="phone"
              required
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Shipping Address</label>
            <textarea
              name="address"
              required
              className="form-input"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="modal-order-button"
          >
            {isSubmitting ? 'Processing...' : 'Place Order'}
          </button>

          {status.message && (
            <div className={`status-message ${status.type}`}>
              {status.message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CheckoutModal;