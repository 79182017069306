import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'lucide-react';
import '../stylings/NavigationBar.css';
import CheckoutModal from './CheckoutModal.jsx';

const NavigationBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const links = [
    { path: '/main', text: 'Home' },
    { path: '/tours', text: 'Tours' },
    { path: '/transportation', text: 'Transportation' },
    { path: '/about', text: 'About' },
    { path: '/contact', text: 'Contact' }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="nav-container">
      <div className="mobile-nav">
        <button
          onClick={toggleMenu}
          className="hamburger-button"
          aria-label="Toggle menu"
        >
          <Menu size={24} />
        </button>
        <button 
          onClick={() => setIsModalOpen(true)}
          className="nav-button mobile-checkout"
        >
          Reserve a tour
        </button>
        <CheckoutModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />
      </div>

      {/* Desktop Navigation */}
      <div className="desktop-nav">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className={`nav-link ${location.pathname === link.path ? 'active' : ''}`}
          >
            {link.text}
          </Link>
        ))}
        <button 
          onClick={() => setIsModalOpen(true)}
          className="nav-button desktop-checkout"
        >
          Reserve a tour
        </button>

        <CheckoutModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />

      </div>

      {/* Mobile Navigation Menu */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className={`nav-link ${location.pathname === link.path ? 'active' : ''}`}
            onClick={() => setIsMenuOpen(false)}
          >
            {link.text}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default NavigationBar;