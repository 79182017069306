import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar.jsx';
import Hero from './components/Hero.jsx';
import TourSection from './components/TourSection';
import Contact from './components/Contact';
import About from './components/About';
import Tours from './components/Tours';
import Transportation from './components/Transportation'
import transportImage from './resources/transport.png';
import './index.css';
import './App.css';

const Layout = ({ children }) => {
  return (
    <div className="app-container">
      <main>
        {children}
      </main>
      <footer>
        <p>© 2024 Armand Tourism & Transportation. All rights reserved.</p>
      </footer>
    </div>
  );
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <BrowserRouter>
      <Layout>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Navigate to="/main" replace />} />
          <Route path="/main" element={
            <>
              <Hero />
              <TourSection />
              <div id="transportation">
                <h3>Transportation You Can Trust</h3>
                <img src={transportImage} alt="transportation image" />
              </div>
            </>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/transportation" element={<Transportation />} />
          <Route path="/tours" element={<Tours />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;