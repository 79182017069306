import React, { useEffect, useRef, useState } from 'react';
import '../stylings/TourSection.css';

import tourImg1 from '../resources/French-Quarter.png';
import tourImg2 from '../resources/tree.png';
import tourImg3 from '../resources/nola_food.jpg';

const tourData = [
  {
    id: 'french_quarter',
    title: 'French Quarter Footsteps',
    image: tourImg1,
    description: 'Explore the historic French Quarter with our expert guides. Discover fascinating architecture and rich cultural heritage.'
  },
  {
    id: 'swamp_cypress',
    title: 'Swamp and Cypress Sites',
    image: tourImg2,
    description: 'Venture into the mysterious swamps and cypress forests. Experience unique ecosystems and wildlife up close.'
  },
  {
    id: 'food_tour',
    title: 'Crescent City Culinary Crawl',
    image: tourImg3,
    description: 'Savor the flavors of New Orleans cuisine while exploring the city\'s culinary hotspots and hidden gems.'
  }
];

const TourSection = () => {
  const [visibleTours, setVisibleTours] = useState({});
  const [hoveredTour, setHoveredTour] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const tourRefs = useRef({});
  const containerRef = useRef(null);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile && containerRef.current) {
      const checkVisibility = () => {
        // Get the viewport height and the tour package height
        const viewportHeight = window.innerHeight;
        const tourHeight = tourRefs.current[Object.keys(tourRefs.current)[0]]?.offsetHeight || 0;
        
        // Define the center region
        const centerRegionStart = (viewportHeight - tourHeight) / 2 + 100;
        const centerRegionEnd = centerRegionStart + tourHeight;

        // Check each tour package
        Object.entries(tourRefs.current).forEach(([tourId, element]) => {
          if (element) {
            const rect = element.getBoundingClientRect();
            const bottomPosition = rect.bottom;
            
            // Check if the bottom of the tour package is within the center region
            const isInCenterRegion = bottomPosition >= centerRegionStart && 
                                   bottomPosition <= centerRegionEnd;

            setVisibleTours(prev => ({
              ...prev,
              [tourId]: isInCenterRegion
            }));
          }
        });
      };

      // Add scroll event listener
      window.addEventListener('scroll', checkVisibility);
      // Initial check
      checkVisibility();

      return () => {
        window.removeEventListener('scroll', checkVisibility);
      };
    }
  }, [isMobile]);

  const handleMouseEnter = (tourId) => {
    if (!isMobile) {
      setHoveredTour(tourId);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setHoveredTour(null);
    }
  };

  return (
    <section id="tours" className="section">
      <h2>Our Tours</h2>
      <div className="tour-container" ref={containerRef}>
        {tourData.map((tour) => (
          <div
            key={tour.id}
            ref={el => tourRefs.current[tour.id] = el}
            data-tour-id={tour.id}
            className="tour-package"
            onMouseEnter={() => handleMouseEnter(tour.id)}
            onMouseLeave={handleMouseLeave}
            style={{
              '--scale': (isMobile ? visibleTours[tour.id] : hoveredTour === tour.id) ? '1.1' : '1',
              '--title-opacity': (isMobile ? visibleTours[tour.id] : hoveredTour === tour.id) ? '0' : '1',
              '--info-opacity': (isMobile ? visibleTours[tour.id] : hoveredTour === tour.id) ? '1' : '0'
            }}
          >
            <div className="tour-image-container">
              <img 
                src={tour.image} 
                alt={tour.title}
                style={{
                  transform: `scale(var(--scale))`,
                  transition: 'transform 0.3s ease'
                }}
              />
            </div>
            <div 
              className="tour-info"
              style={{
                opacity: 'var(--info-opacity)',
                transition: 'opacity 0.5s ease'
              }}
            >
              {tour.description}
            </div>
            <h3 
              style={{
                opacity: 'var(--title-opacity)',
                transition: 'opacity 0.3s ease'
              }}
            >
              {tour.title}
            </h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TourSection;