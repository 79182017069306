import React from 'react';
import TourCard from './TourCard';
import frenchQuarterImg from '../resources/nola_food.jpg';
import gardenDistrictImg from '../resources/garden_district.jpg';
import cemeteryVoodooImg from '../resources/cemetery.jpg';
import cocktailHistoryImg from '../resources/cocktails.webp';
import '../stylings/Tours.css';

const Tours = () => {
    const tours = [
      {
        title: "French Quarter Food",
        description: "Explore the culinary delights of the French Quarter while learning about its rich history and culture.",
        price: 89,
        duration: "3 hours",
        rating: 5,
        imageUrl: frenchQuarterImg
      },
      {
        title: "Garden District Walk",
        description: "Discover the architectural beauty and fascinating stories of New Orleans' most prestigious historic neighborhood.",
        price: 49,
        duration: "2 hours",
        rating: 4,
        imageUrl: gardenDistrictImg
      },
      {
        title: "Cemetery & Voodoo Sights",
        description: "Visit the famous St. Louis Cemetery No. 1 and learn about New Orleans' unique burial practices and voodoo traditions.",
        price: 69,
        duration: "2.5 hours",
        rating: 5,
        imageUrl: cemeteryVoodooImg
      },
      {
        title: "Cocktail History & Drinks",
        description: "Sample classic New Orleans cocktails while exploring the historic bars and restaurants where they were invented.",
        price: 79,
        duration: "2.5 hours",
        rating: 4,
        imageUrl: cocktailHistoryImg
      }
    ];
  
    return (
      <div className="container" id='tours'>
        <h1 className="tours-heading">Explore Our Tours</h1>
        <div className="tours-grid">
          {tours.map((tour, index) => (
            <TourCard key={index} {...tour} />
          ))}
        </div>
      </div>
    );
  };
  
export default Tours;